import React, { useState } from 'react'
import EllipseOne from '../assets/images/ellipse-one.png'
import EllipseTwo from '../assets/images/ellipse-two.png'
import InputField from '../components/InputField'
import { FaFacebook } from "react-icons/fa";
// import { FaInstagram } from "react-icons/fa";
// import { FaTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { BiSolidEnvelope } from "react-icons/bi";
import { FaPhone } from "react-icons/fa6";

const ContactUs = () => {
  const [typeCategory, setTypeCategory] = useState("");
  const category = [{label: "High", value: "High" }, {label: "Medium", value: "Medium"}, {label: "Low", value:"Low"}];
  const socials = [
    {image: <FaFacebook className="w-[23px] h-[23px] text-white"/>, link: 'https://www.facebook.com/HamzaaINC'},
    // {image: <FaInstagram className="w-[23px] h-[23px] text-white" />, link: ''},
    // {image: <FaTwitter className="w-[23px] h-[23px] text-white" />, link: ''},
    {image: <FaLinkedin className="w-[23px] h-[23px] text-white" />, link: 'https://www.linkedin.com/company/hamzaa/'}
  ]
  return (
    <div className='mt-[160px] lg:mt-[176px] mb-[60px] lg:mb-[97px] font-dmsans max-w-full overflow-hidden'>
      <div className='flex flex-col gap-[6px] items-center justify-center mb-[36px] lg:mb-[60px] px-6  md:px-0'>
<h1 className='font-spaceGrotesk font-[700] md:font-[500] text-[24px] md:text-[32px] leading-[40px] tracking-[0.3px] text-black text-center'>Contact Us</h1>
<p className='font-[400] text-[14px] leading-[20px] md:text-[16px] md:leading-[24px] tracking-[0.3px] text-[#48494D] text-center'>When you fill out the form, our team will get back to you within 24 hours.</p>
      </div>
      <div className='flex flex-col items-center lg:flex-row justify-center w-full px-6 gap-[40px] lg:gap-[71px] lg:px-[80px]'>
        <div className='lg:h-[639px] w-full h-[403px] lg:w-[380px] rounded-[9px] bg-foot flex flex-col items-start p-[30px] relative'>
        <div className='flex flex-col gap-[40px]'>
        <h2 className='font-[700] text-white text-[24px] leading-[32px]'>Contact Information</h2>
        <div className='flex flex-col gap-6 lg:gap-[15px]'>
          <div className='flex gap-[14px] items-center'>
          <FaPhone  className='w-[24px] h-[24px] text-[#E5E5E5]'/>
            <p className='font-montserrat font-[400] text-[16px] leading-[24px] tracking-[0.3px] text-white'>018880123</p>
          </div>
          <div className='flex gap-[14px] items-center'>
          <BiSolidEnvelope  className='w-[24px] h-[24px] text-[#E5E5E5]' />
            <p className='font-montserrat font-[400] text-[16px] leading-[24px] tracking-[0.3px] text-white'>support@hamzaa.ca</p>
          </div>
          <div className='flex lg:hidden items-center gap-[16px]'>
          {socials.map((social, index) => (
              <a href={social.link} key={index}>
              {social.image}
              </a>
            ))}
  </div>
        </div>
        </div>
         <div className='absolute bottom-[36px]'>
         <div className='hidden lg:flex items-center gap-[16px]'>
         {socials.map((social, index) => (
              <a href={social.link} key={index}>
              {social.image}
              </a>
            ))}
  </div>
         </div>
         <div className='absolute lg:bottom-[124px] bottom-[110px] right-[10px] lg:right-[30px] '>
         <img src={EllipseTwo} alt='A background ellipse' className=''/>
         </div>
         <div className='absolute bottom-0 -right-4 lg:right-0 '>
<img src={EllipseOne} alt='A background ellipse'/>
         </div>
        </div>
        <div className='w-full'>
          <form className='w-full flex flex-col gap-[24px]'>
          <div className='flex lg:flex-row flex-col items-center gap-[24px] w-full'>
          <InputField
								label='Full Name'
								// value={}
								// handleValue={}
								placeholder='John Doe'
								name='fullName'
								type='text'
								className='w-full'
                asteriks={true}
                inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
							/>
          <InputField
								label='Shop Name'
								// value={}
								// handleValue={}
								placeholder="John's shop"
								name='shopName'
								type='text'
								className='w-full'
                asteriks={true}
                inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
							/>
              </div>
          <div className='flex lg:flex-row flex-col items-center gap-[24px] w-full'>
          <InputField
								label='Email address'
								// value={}
								// handleValue={}
								placeholder='john@example.com'
								name='email'
								type='text'
								className='w-full'
                asteriks={true}
                inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
							/>
          <InputField
								label='Phone Number'
								// value={}
								// handleValue={}
								placeholder="080123456"
								name='phoneNumber'
								type='text'
								className='w-full'
                asteriks={true}
                inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
							/>
              </div>
          <div className='flex flex-col lg:flex-row items-center gap-[24px] w-full'>
         
          <InputField
								label='Subject'
								// value={}
								// handleValue={}
								placeholder="Subject"
								name='subject'
								type='text'
								className='w-full'
                asteriks={true}
                inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
							/>
               <InputField
								label='Priority'
								value={typeCategory}
								handleValue={setTypeCategory}
								name='priority'
								className='w-full'
                asteriks={true}
                type="select"
                options={category}
                 selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px]"
							/>
              </div>
              <InputField
								label='Message'
								// value={}
								// handleValue={}
								placeholder="Message"
								name='message'
								type='textarea'
								className='w-full'
                asteriks={true}
							/>
              <div className='flex justify-center items-center mt-[16px]'>
              <button className='w-[147px] h-[52px] lg:w-[205px] lg:h-[60px] flex justify-center items-center rounded-[8px] py-[18px] px-[20px] bg-primary border-[2px] border-foot font-[700] text-[14px] leading-[20px] md:text-[16px] md:leading-[24px] text-center text-white'>Submit</button>
              </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactUs