import ImageOne from '../assets/images/feature-one.png';
import ImageTwo from '../assets/images/feature-two.png';
import ImageThree from '../assets/images/feature-three.png';
import ImageFour from '../assets/images/feature-four.png';
import ImageFive from '../assets/images/feature-five.png';
import ImageSix from '../assets/images/feature-six.png';

const features = [
    {id: 1,
        image: ImageOne,
        title: 'Customer Management',
        description: "Hamzaa enhances interaction management between businesses and customers to boost retention, increase sales, and improve satisfaction. It streamlines communication, automates tasks, personalised engagements, and utilizes data insights to drive long-term customer loyalty.",
    },
    {id: 2,
        image: ImageTwo,
        title: 'Inventory Management',
        description: "This feature in Hamzaa provides auto repair shops with real-time tracking of parts and supplies, ensuring they always have the right items in stock. It helps prevent delays caused by stockouts, reduces overstocking costs, and optimizes order management. By integrating with our scheduling and invoicing, the system ensures that repair jobs are completed efficiently, improving customer satisfaction. Additionally, it streamlines reordering processes, tracks part usage, and provides insights into inventory trends, supporting better business decisions and profitability.",
    },
    {id: 3,
        image: ImageThree,
        title: 'Part Ordering',
        description: "Part ordering within Hamzaa simplifies the procurement process by enabling shops to order parts directly from vendors through the system. This feature ensures accurate part selection based on repair needs, reduces manual errors, and prevents delays due to missing parts. With automated reordering, shops can maintain optimal inventory levels, minimizing downtime. Additionally, tracking order statuses in real time helps streamline operations, improve job scheduling, and enhance customer satisfaction by ensuring repairs are completed on time.",
    },
    {id: 4,
        image: ImageFour,
        title: 'Schedule Appointment',
        description: "This feature streamlines the booking process by allowing customers to schedule services online or through automated reminders. It optimizes shop operations by managing technician availability, reducing overbooking, and ensuring efficient time management. With automated reminders, shops can minimize no-shows, improving service efficiency. Additionally, this feature enhances the customer experience by offering convenient booking options, improving communication, and building trust through timely service delivery.",
    },
    {id: 5,
        image: ImageFive,
        title: 'Invoice Generating',
        description: "The invoice generation feature simplifies the billing process by creating accurate, professional invoices automatically. It reduces manual errors, ensures consistent pricing, and speeds up payment collection. This feature allows easy tracking of outstanding payments, integrates with payment gateways, and supports multiple formats (e.g., PDFs or email invoices) for customer convenience. Automated invoicing not only saves time but also enhances transparency, boosting customer trust and satisfaction.",
    },
    {id: 6,
        image: ImageSix,
        title: 'VIN Lookup',
        description: "Hamzaa’s VIN (Vehicle Identification Number) lookup feature allows quick access to detailed vehicle information, such as make, model, year, engine type, and service history. This ensures accurate part matching, streamlines repair processes, and reduces errors in service recommendations. By eliminating guesswork, it saves time for technicians, improves operational efficiency, and enhances customer satisfaction by delivering precise repairs and maintenance tailored to the specific vehicle.",
    },
]
export default features