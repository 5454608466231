import React from "react";
import FeatureBox from "./FeatureBox";
import ImageOne from "../assets/images/service-image-one.png";
import ImageTwo from "../assets/images/service-image-two.png";
import ImageThree from "../assets/images/service-image-three.png";
import ScheduleAppointment from "../assets/images/schedule-appointment.png";
import JohnDetails from "../assets/images/john-details.png";
import StockAlert from "../assets/images/stock-alert.png";
import Refer from "../assets/images/refer.png";
import SupplierList from "../assets/images/suppliers-list.png";
// import "react-lazy-load-image-component/src/effects/blur.css";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import useScrollTop from "../custom-hooks/useScrollToTop";

const ImageBoxOne = () => {
  
  // const LazyWebImageOne = ({ src, alt }) => {
  //   return <LazyLoadImage alt={alt} src={src} height={350} width={500} effect="blur" />;
  // };
  // const LazyMobileImageOne = ({ src, alt }) => {
  //   return <LazyLoadImage alt={alt} src={src} height={235} width={'100%'} effect="blur" />;
  // };
  return (
    <div className="relative my-[60px] md:my-0">
      <img
        src={JohnDetails}
        alt="John's Details"
        className="absolute -top-[47px] z-30 md:left-[13px] left-[8px] w-[144px] h-[75px] md:w-[194px] md:h-[96px]"
      />
      <div><img src={ImageOne} alt="Feature" className="md:w-[500px] md:h-[350px] h-[235px] w-full"/></div>
      {/* <div className="hidden md:block"><LazyWebImageOne src={ImageOne} alt="Feature"/></div>
      <div className="md:hidden block"><LazyMobileImageOne src={ImageOne} alt="Feature"/></div> */}
      
      <img
        src={ScheduleAppointment}
        alt="Schedule Appointment Modal"
        className="absolute top-[150px] right-[8px] md:top-[275px] md:right-[47px] w-[199px] h-[139px] md:w-[239px] md:h-[169px] z-30"
      />
    </div>
  );
};
const ImageBoxTwo = () => {
  // const LazyWebImageTwo = ({ src, alt }) => {
  //   return <LazyLoadImage alt={alt} src={src} height={350} width={500} effect="blur" />;
  // };
  // const LazyMobileImageTwo = ({ src, alt }) => {
  //   return <LazyLoadImage alt={alt} src={src} height={235} width={'100%'} effect="blur" />;
  // };
  return (
    <div className="relative my-[80px] md:my-0">
      <img
        src={StockAlert}
        alt="Stock alert modal"
        className="absolute z-30 left-[8px] -top-[47px] md:left-[13px] w-[144px] md:w-[192px] h-[69px]"
      />
      <div><img src={ImageTwo} alt="Feature" className="md:w-[500px] md:h-[350px] h-[235px] w-full"/></div>
      {/* <div className="hidden md:block"><LazyWebImageTwo src={ImageTwo} alt="Feature"/></div>
      <div className="md:hidden block"><LazyMobileImageTwo src={ImageTwo} alt="Feature"/></div> */}
      <img
        src={Refer}
        alt="Referral modal"
        className="absolute z-30 top-[170px] md:top-[275px] right-[8px] md:right-[47px] w-[203px] h-[134x] md:w-[264px] md:h-[156px]"
      />
    </div>
  );
};
const ImageBoxThree = () => {
  // const LazyWebImageThree = ({ src, alt }) => {
  //   return <LazyLoadImage alt={alt} src={src} height={350} width={500} effect="blur" />;
  // };
  // const LazyMobileImageThree = ({ src, alt }) => {
  //   return <LazyLoadImage alt={alt} src={src} height={235} width={'100%'} effect="blur" />;
  // };
  return (
    <div className="relative my-[40px] md:my-0">
    <div><img src={ImageThree} alt="Feature" className="md:w-[500px] md:h-[350px] h-[235px] w-full"/></div>
      {/* <div className="hidden md:block"><LazyWebImageThree src={ImageThree} alt="Feature"/></div>
     <div className="md:hidden block"><LazyMobileImageThree src={ImageThree} alt="Feature"/></div> */}
      <img
        src={SupplierList}
        alt="Supplier's List Modal"
        className="absolute top-[150px] md:top-[275px] right-[8px] md:right-[47px] md:w-[243px] md:h-[169px] w-[183px] h-[133px] z-30"
      />
    </div>
  );
};
const Features = () => {
  const navigate = useNavigate();
  const { scrollToTop } = useScrollTop();
  const handleFeaturesPage = () => {
scrollToTop();
navigate('/features');
  }
  const features = [
    {
      id: 1,
      title: "Seamless Customer Retention",
      description:
        "Retain and access customer information, schedule appointments, and create detailed service records with a few clicks. Your customer data and service history are all centrally located for a smooth and efficient workflow.",
      tag: "RECORD KEEPING",
      image: <ImageBoxOne />,
    },
    {
      id: 2,
      title: "Efficiently Manage Inventory",
      description:
        "Move beyond manual record-keeping and embrace instant access to your inventory status. Track parts, set reorder points, and conduct audits.",
      tag: "BUILT TO TRACK",
      image: <ImageBoxTwo />,
    },
    {
      id: 3,
      title: "Effortless Parts Ordering",
      description:
        "Simplify your car parts sourcing with Hamzaa. Access all your needed parts in one place, saving you the trouble of navigating multiple supplier websites.",
      tag: "MAKE ORDERING EASY",
      image: <ImageBoxThree />,
    },
  ];
  return (
    <div className="font-dmsans md:px-[140px] px-6">
      <div className="md:mt-[80px] mt-[60px] flex justify-center items-center">
        <div
          data-aos="fade-up"
          className="flex flex-col md:gap-6 gap-2 justify-center items-center md:w-[860px] h-[160px]"
        >
          <h2 className="font-spaceGrotesk font-[700] text-[24px] leading-[40px] md:text-[48px] md:leading-[52px] tracking-[0.26px] text-[#161414]">
          What We Offer
          </h2>
          <p className="text-center font-[400] text-[14px] md:text-[20px] leading-[20px] md:leading-[28px] tracking-[0.26px] text-[#48494D] ">
            Empowering mechanics and transform shop operations with our
            all-in-one auto repair shop management software. Hamzaa streamlines
            processes, boosts efficiency, and helps grow your business—without
            the hassle of juggling multiple tools.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:gap-[170px] justify-center items-center md:mt-[129px] mt-[40px]">
        {features.map((feature, index) => (
          <FeatureBox
            key={feature.id}
            title={feature.title}
            description={feature.description}
            image={feature.image}
            tag={feature.tag}
            isReversed={index === features.length - 1}
          />
        ))}
      </div>
      <div className="md:mt-[150px] md:mb-[80px] mt-[40px] mb-[60px] flex justify-center items-center">
       <button onClick={handleFeaturesPage}  className="bg-primary w-[168px] h-[52px] md:w-[350px] md:h-[60px] flex justify-center items-center border-[2px] border-foot rounded-[8px] font-dmsans font-[700] text-[14px] leading-[20px] md:text-[16px] md:leading-[24px] text-center text-white p-3 ">
          Explore All Features
        </button>

      </div>
    </div>
  );
};

export default Features;
