import React from "react";
import actionSpeech from "../assets/images/action-speech.svg";
import heroImage from "../assets/images/hero-image.png";
// import "react-lazy-load-image-component/src/effects/blur.css";
// import { LazyLoadImage } from "react-lazy-load-image-component";

const Header = () => {
  // const LazyHeaderWebImage = ({ src, alt }) => {
  //   return <LazyLoadImage alt={alt} src={src} width={1024} effect="blur" />;
  // };
  // const LazyHeaderIpadImage = ({ src, alt }) => {
  //   return <LazyLoadImage alt={alt} src={src} width={800} effect="blur" />;
  // };
  // const LazyHeaderMobileImage = ({ src, alt }) => {
  //   return <LazyLoadImage alt={alt} src={src} width={"100%"} effect="blur" />;
  // };
  return (
    <div className="mt-[159px] md:mt-[200px] flex flex-col items-center font-dmsans">
      <div
        data-aos="fade-down"
        className="flex flex-col justify-center items-center gap-[4px]"
      >
      {/* Hero message web view */}
        <div className="md:block hidden px-6">
          <h1 className="font-spaceGrotesk font-[700] text-[24px] md:text-[35px] lg:text-[48px] leading-[40px] lg:leading-[58px] text-center text-[#161414]">
            Transform Your Auto Repair Experience – <br/>
            The Future is {" "}
            <span className="text-primary font-miltonian">Hamzaa</span>
            <img
              src={actionSpeech}
              alt="Action speech icon"
              className="w-[20px] h-[20px] md:w-[40px] md:h-[30px] inline-flex items-center -translate-x-2 -translate-y-2 md:-translate-x-6 md:-translate-y-5 animate-ping "
            />
          </h1>
        </div>
        {/* Hero message mobile view */}
        <div className="md:hidden block px-4">
          <h1 className="font-spaceGrotesk font-[700] text-[24px] md:text-[48px] leading-[40px] md:leading-[58px] text-center text-[#161414]">
            Transform Your Auto Repair Experience – The Future is<br/>
            <span className="text-primary font-miltonian">Hamzaa</span>
            <img
              src={actionSpeech}
              alt="Action speech icon"
              className="w-[20px] h-[20px]  inline-flex items-center -translate-x-2 -translate-y-2 animate-ping "
            />
          </h1>
        </div>
        <div className="flex flex-col justify-center items-center px-4">
          <p className="text-[14px] md:text-[20px] font-[400] leading-[20px] md:leading-[28px] tracking-[0.09px] text-center text-[#5B617A]">
            {" "}
            Innovative solution for customer retention, parts ordering, real
            time insights and many more.
          </p>
          <a
            href="https://dashboard.hamzaa.ca/signup"
            rel="noreferrer"
            className="mt-6 md:mt-[28px] w-[155px] md:w-[180px] h-[52px] md:h-[60px] border-[2px] rounded-[8px] p-4 md:py-[18px] md:px-[18px] flex justify-center items-center bg-primary border-[#0A0A0A] font-[700] text-[14px] md:text-[16px] leading-[20px] md:leading-[24px] text-center text-white"
          >
            Get Started
          </a>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center w-full  mt-[80px] ">
        <img className="w-full md:w-[800px] xl:w-[1024px]" src={heroImage}  alt="A sneak peek of Hamzaa dashboard"/>
      </div>
      {/* <div className="hidden md:hidden lg:flex flex-col justify-center items-center w-full  mt-[80px]">
        <LazyHeaderWebImage
          src={heroImage}
          alt="A sneak peek of Hamzaa dashboard"
         
        />
      </div> */}
      {/* <div className="hidden md:flex lg:hidden flex-col justify-center items-center w-full  mt-[80px]">
        <LazyHeaderIpadImage
          src={heroImage}
          alt="A sneak peek of Hamzaa dashboard"
         
        />
      </div> */}
      {/* <div className="flex md:hidden flex-col justify-center items-center w-full px-[25px] mt-[46px]">
        <LazyHeaderMobileImage
          src={heroImage}
          alt="A sneak peek of Hamzaa dashboard"
         
        />
      </div> */}
    </div>
  );
};

export default Header;
